<template>
    <v-container class="pa-4 py-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-8">
             <span v-if="isView == true"><v-icon @click="viewBack">mdi mdi-arrow-left</v-icon> <span>Highest Risks for {{viewTitle}}</span></span>
            <span v-if="isView == false"><v-icon>mdi-format-list-text</v-icon> View All ORMP</span>
            <div class="float-right row mr-2">
                <v-select
                    v-if="isView == false"
                    item-text="departmentName"
                    item-value="productName"
                    :items="returnDept"
                    v-model="deptFilter"
                    label="Select Department"
                    class="mr-4 col"
                    hide-details
                    outlined
                    style="width: 200px;"
                    v-show="returnORMPFilter"
                    dense
                    >
                </v-select> 
              
                <router-link 
                    :to="{name: 'CreateCoverage'}" 
                    tag="button" 
                    >
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2 col"   
                        >
                        <v-icon>mdi-plus</v-icon>
                        New ORMP
                    </v-btn>
                </router-link>
            </div>
        </div>

        <div v-if="isView == false">
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>
                            ORMP's 
                            <v-spacer></v-spacer>
                            <v-text-field
                                class="shrink" 
                                style="width:250px"
                                v-model="searchORMP"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-data-table
                            :search="searchORMP"
                            :headers="headers"
                            :items="returnCoverage()"
                            sort-by="calories"
                            class="elevation-1"
                            >
                    
                            <template v-slot:item.actions="{ item }">
                                <v-row class="ml-1">
                                    <router-link :to="{name: 'ViewAllORMP', params: {id: item['.key']}}">
                                        <v-icon
                                        small
                                        >
                                        mdi mdi-arrow-right-bold-circle
                                        </v-icon>    
                                    </router-link> 
                                    <v-icon
                                        small
                                        @click="deleteItem(item)"
                                        >
                                        mdi-delete
                                    </v-icon>
                                    <v-icon
                                    v-if="returnORMPFilter && item.isApproved == 'Pending'"
                                    small
                                        @click="approveCoverage(item)"
                                        class="mr-2"
                                        >
                                        mdi-checkbox-marked-circle
                                    </v-icon>
                                    <v-icon
                                    v-if="returnORMPFilter && item.isApproved == 'Approved'"
                                    small
                                        @click="unapproveCoverage(item)"
                                        class="mr-2"
                                        >
                                        mdi-close-circle
                                    </v-icon>
                                </v-row>
                            </template>

                            <template v-slot:item.CreatedAt="{ item }">
                                {{$moment(item.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')}}
                            </template>
                        
                            <template v-slot:item.DateCovered="{ item }">
                                {{item.startDate}}
                            </template>
                            <!-- 
                            <template v-slot:item.approval="{ item }">
                                <span v-if="item.isApproved == 'Pending'">Pending</span>
                                <span v-else>Approved</span>
                            </template> -->

                            <template v-slot:no-data>
                                <v-btn
                                    color="primary"
                                >
                                    Reset
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="pt-3">
                <v-col>
                    <v-card>
                        <v-card-title>
                            Transpired ORMP's
                            <v-spacer></v-spacer>
                            <v-text-field
                                class="shrink" 
                                style="width:250px"
                                v-model="searchTranspiredORMP"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                        <v-data-table
                        :headers="headers"
                        :search="searchTranspiredORMP"
                        :items="returnCoverageTranspired('ORMPCoverage')"
                        sort-by="calories"
                        class="elevation-1"
                        >

                        <template v-slot:item.actions="{ item }">
                            <v-row class="ml-1">
                                <router-link :to="{name: 'ViewAllORMP', params: {id: item['.key']}}">
                                    <v-icon
                                    small
                                    >
                                    mdi mdi-arrow-right-bold-circle
                                    </v-icon>    
                                </router-link> 
                                <v-icon
                                    small
                                    @click="deleteItem(item)"
                                    >
                                    mdi-delete
                                </v-icon>
                                <v-icon
                                v-if="returnORMPFilter && item.isApproved == 'Pending'"
                                small
                                    @click="approveCoverage(item)"
                                    class="mr-2"
                                    >
                                    mdi-checkbox-marked-circle
                                </v-icon>
                                <v-icon
                                v-if="returnORMPFilter && item.isApproved == 'Approved'"
                                small
                                    @click="unapproveCoverage(item)"
                                    class="mr-2"
                                    >
                                    mdi-close-circle
                                </v-icon>
                            </v-row>
                        </template>
            
                        <template v-slot:item.CreatedAt="{ item }">
                            {{$moment(item.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')}}
                        </template>
                        
                        <template v-slot:item.DateCovered="{ item }">
                            {{item.startDate}}
                        </template>

                        <!-- <template v-slot:item.approval="{ item }">
                            <span v-if="item.isApproved == 'Pending'">Pending</span>
                            <span v-else>Approved</span>
                        </template> -->

                        <template v-slot:no-data>
                            There are no transpired ORMP's yet
                        </template>
                    </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            
            
            <v-row class="pt-3">
                <v-col>
                    <v-card>
                        <v-card-title>
                              Last Six Months
                            <v-spacer></v-spacer>
                            <v-text-field
                                class="shrink" 
                                style="width:250px"
                                v-model="searchLastSixMonthsORMP"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                        </v-card-title>
                         <v-data-table
                         :search="searchLastSixMonthsORMP"
                        :headers="headers2"
                        :items="returnCoverageSixMonths()"
                        sort-by="calories"
                        class="elevation-1"
                        >
                        <template v-slot:item.ViewDetails="{ item }">
                            <v-icon
                                @click="viewItem(item, 'ORMP')"
                                small
                                class="ml-4"
                                >
                                mdi mdi-arrow-right-bold-circle
                            </v-icon>    
                        </template>
            
                         <template v-slot:item.Progress="{ item }">
                            <v-progress-linear
                                :value="returnValue(item)"
                                color="primary"
                                height="25"
                                >
                                <template v-slot:default>
                                    <strong>{{returnValue(item)}}%</strong>
                                </template>
                            </v-progress-linear>
                        </template>
        
                        <template v-slot:no-data>
                            There are no ORMP's yet
                        </template>
                    </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>

       

        <div v-if="isView == true"> 
             <v-data-table
            :headers="headers3"
            :items="dialogItems"
            class="elevation-1"
            item-key=".key"
            >

            <!-- <template v-slot:expanded-item="{ item }">
              <td colspan="12">
                <v-row>
                  <v-col cols="4"> 
                    <v-list-item-title><h4>Objectives</h4></v-list-item-title>
                    <v-list 
                      v-for="(cause, index) in item.Objectives"
                      :key="index"
                      ><v-list-item>{{ cause }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                  <v-col cols="4"> 
                    <v-list-item-title><h4>Causes</h4></v-list-item-title>
                    <v-list 
                      v-for="(cause, index) in item.Causes"
                      :key="index"
                      ><v-list-item>{{index+1}} - {{ cause }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                  <v-col cols="4">
                    <v-list-item-title><h4>Impacts</h4></v-list-item-title>
                      <v-list 
                        v-for="(impact, index) in item.Impacts"
                        :key="index"
                        ><v-list-item class="listItem">{{ impact }}</v-list-item>
                    </v-list>
                  </v-col>
                  
                    <v-col cols="3">
                      <v-list-item-title><h4>Existing Risk Action for Causes</h4></v-list-item-title>
                        <div v-for="(riskaction, index) in item.RiskActions"
                          :key="index">
                          <v-list-item> 
                            <v-col><span v-for="(cause, index) in riskaction.causes"
                              :key="index">{{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                            </v-col>
                            <v-col>{{riskaction.text}}</v-col>
                          </v-list-item>
                        </div>
                    </v-col>
                </v-row>
              </td>
            </template> -->

            <template v-slot:item.actions="{ item }">
              <!-- <router-link :to="{name: 'ViewORMP', params: {id: getID(item)}}">-->
              <v-row>
                  <v-icon  
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    >
                    mdi-pencil
                  </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                  class="mr-2"
                  >
                  mdi-delete
                </v-icon>
                <router-link :to="{name: 'RiskLevel', params: {id: getID(item)}}">
                  <v-icon
                    small
                    >
                    mdi-flag-variant
                  </v-icon>    
                </router-link> 
              </v-row>
            </template>

            <!-- <template v-slot:item.information="{ item }">
                <v-chip 
                  color="green" 
                  text-color="white" 
                  class="mt-1" 
                  v-if="levelStatusTime(item)"
                  >{{levelStatusTime(item)}}
                </v-chip>
            </template> -->

             <template v-slot:item.DateCovered="{ item }">
              <td v-if="$route.path == '/viewallprmpcoverage'">
                {{findCoverage(item.CoverageId, 'PRMPCoverage')[0].startDate}}
              </td>
              <td v-else>
                {{findCoverage(item.CoverageId, 'ORMPCoverage')[0].startDate}}
              </td>
            </template>

            <template v-slot:item.AffectedStakeholders="{ item }">
              <td>
                <div v-for="(stakeholder, index) in item.AffectedStakeholders"
                  :key="index">
                  <span>{{stakeholder.AffectedStakeholder}}<span v-if="index != (item.AffectedStakeholders.length -1)">,</span></span>
                </div>
              </td>
            </template>

            <template v-slot:item.RiskTreatmentOwner="{ item }">
              <td>
                <div
                  v-for="(riskowner, index) in item.RiskTreatmentOwner"
                  :key="index">
                  <span>{{riskowner.RiskTreatmentOwner}}<span v-if="index != (item.RiskTreatmentOwner.length -1)">,</span></span>
                </div>
              </td>
            </template>

            <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  >
                    Reset
                </v-btn> 
            </template>
          </v-data-table>
        </div>

     
        <!-- <div class="text-center">
            <v-dialog
                v-model="dialogReport"
                width="500"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="red lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >
                    Click Me
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title class="headline grey lighten-2">
                    {{dialogItems.title}}
                    </v-card-title>
                    <v-card-text class="pt-4">
                    <div v-if="dialogItems.max">
                        <h3 v-if="dialogItems.max.length == 0">There are no ORMP's yet</h3>
                    <v-list v-else>
                        
                        <v-list-title v-for="(item, index) in dialogItems.max" :key="index">
                            <h3>{{findCoverage(item.CoverageId)[0].startDate}}</h3>
                            <h3>{{item.RiskName.RiskName}}</h3>
                            <v-list-item>Vulnerability: {{item.Vulnerability}}</v-list-item>
                        </v-list-title>
                    </v-list>
                    </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        I accept
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div> -->
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            searchLastSixMonthsORMP: '',
            searchLastSixMonthsPRMP: '',
            isView: false,
            searchTranspiredPRMP: '',
            searchPRMP: '',
            searchORMP: '',
            searchTranspiredORMP: '',
            selectedProduct: '',
            deptFilter: 'SYSDEV',
            dialog: false,
            dialogReport: false,
            dialogItems: '',
            viewTitle: '',
            headers: [
                {
                text: 'Date Covered',
                align: 'start',
                sortable: false,
                value: 'startDate',
                },
                { text: 'Created At', value: 'CreatedAt' },
                { text: 'Created By', value: 'CreatedBy' },
                { text: 'Approval', value: 'isApproved'},
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headers2: [
                {
                text: 'Period',
                align: 'start',
                sortable: false,
                value: 'title',
                },
                { text: 'Progress', value: 'Progress' },
                { text: 'View Details', value: 'ViewDetails' },
            ],
            headers3: [
                {
                text: 'Risk Name',
                align: 'start',
                sortable: false,
                value: 'RiskName.RiskName',
                },
                { text: 'Date Covered', value: 'DateCovered' },
                { text: 'Vulnerability', value: 'Vulnerability' },
            ]
        }),

        watch: {
            deptFilter: function (val) {
                if(this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM'){
                    this.$store.commit('logs/setDataOwner', val)
                    console.log(this.$store.getters['logs/getDataOwner'],'data owner in watch');  
                }
            },    
            selectedProduct: function (val) {
                const PRMPArrayRoute = ['CreatePRMPCoverage','ViewAllPRMP','RiskLevelPRMP','CreatePRMPRow','ViewAllPRMPCoverage']

                let isPRMP = PRMPArrayRoute.includes(this.$router.currentRoute.name)
                if(isPRMP){
                    this.$store.commit('logs/setDataOwner', val)
                    console.log(this.$store.getters['logs/getDataOwner'],'data owner in watch');  
                }
            },         
        },

        created(){

            const PRMPArrayRoute = ['CreatePRMPCoverage','ViewAllPRMP','RiskLevelPRMP','CreatePRMPRow','ViewAllPRMPCoverage']

            let isPRMP = PRMPArrayRoute.includes(this.$router.currentRoute.name)

            if(this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM'){
                this.$store.commit('logs/setDataOwner', this.deptFilter)
            } else if (isPRMP) {
                this.$store.commit('logs/setDataOwner', this.selectedProduct)
            }
            console.log(this.$store.getters['logs/getDataOwner'],'data owner');           
        },

        firestore(){
            return {
                Product: this.$db.collection('Product'),
                ORMPCoverage: this.$db.collection('ORMPCoverage'),
                PRMPCoverage: this.$db.collection('PRMPCoverage'),
                Departments: this.$db.collection('Departments'),
                ORMP: this.$db.collection('ORMP'),
                ORMPLevels: this.$db.collection('ORMPLevels'),
                PRMP: this.$db.collection('PRMP'),
                PRMPLevels: this.$db.collection('PRMPLevels'),
            }
        },

        computed: {
            checkSelectedProduct(){
                if(this.selectedProduct == ''){
                    return true 
                } else {
                    return false
                }
            },
            returnProducts(){
                if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
                    let arr = []
                    this.Product.map(e=>{
                        arr.push(e.Product)
                    })
                    let filter = this.Product.filter(e=>{
                    return this.$store.getters['useraccount/isAuthenticated'].products.includes(e.Product)
                    })
                    console.log(this.$store.getters['useraccount/isAuthenticated'].products)
                  return filter
                }
                console.log(this.Product)
                return this.Product
            },
            returnORMPFilter(){
                if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
                    return false
                }
                return true
            },
            returnDept(){
                // if(this.Departments.length == 0){
                //     return []
                // } else {
                //       let db = this.Departments.map(a=>{
                //     return {
                //         text: a.departmentName,
                //         value: a.departmentName
                //     }
                // })
                // console.log(db)
                // return db
                // }
                return this.Departments
            }
        },

        

        methods: {
            returnCoverage(){
                let before = this.ORMPCoverage
                let self = this

                let user = self.$store.getters['useraccount/isAuthenticated']
      
                if(user.department !== 'RISK MANAGEMENT TEAM'){
                    return before.filter(a=>{
                        return a.Department == user.department && a.isTranspired == false
                    })    
                }

                if(self.deptFilter !== 'ALL DEPARTMENTS'){
                    return before.filter(a=>{
                        return a.Department == self.deptFilter && a.isTranspired == false
                    })
                }
                return before
            },
            returnCoverageSixMonths(){
                // if(this.returnAllCoverage.length == 0){
                //     return []
                // }
                    //  let filter = this.returnAllCoverage('ORMPCoverage').filter(e=>{
                     
                    //          return e.startDate <= year + this.selectedQuarter.value
                         
                    // })
                    let arr = []
                    let arr1 = []
                    let finalArr = []
            
                    this.returnAllCoverage().map(e=>{
                        let x = {...e}
                        arr.push(x.startDate.slice(0,4))
                    })
                    console.log(arr)
                        let uniqArr = this.$lodash.uniq(arr)
                        let filter = this.returnAllCoverage().filter(e=>{
                            let x = {...e}
                            uniqArr.forEach(f=>{
                                if(x.startDate.slice(0,4) === f){
                                    let y = {...x}
                                    y.Year = x.startDate.slice(0,4)
                                    arr1.push(y)
                                }
                            })
                            
                        })
                        console.log(filter)
                        let grouped = this.$lodash.groupBy(arr1, 'Year')
                        let mapped = this.$lodash.map(grouped, function(items, Year){
                            let arrJanJun = []
                            let arrJulDec = []
                            items.forEach(e=>{
                                if(e.startDate < Year + '-06'){
                                    arrJanJun.push(e)
                                    return {
                                        Year: Year,
                                        JantoJun: arrJanJun,
                                    }
                                } else {
                                    arrJulDec.push(e)
                                }
                            })
                            return {
                                Year: Year,
                                JantoJun: arrJanJun,
                                JultoDec: arrJulDec
                            }
                        })
                        let mapped2 = mapped.map(e=>{
                            return {
                                Year: e.Year,
                                items: e.JantoJun,
                                title: 'January to June' + ' ' + e.Year
                            }
                        })
                        let mapped3 = mapped.map(e=>{
                            return {
                                Year: e.Year,
                                items: e.JultoDec,
                                title: 'July to December' + ' ' + e.Year
                            }
                        })
                        mapped2.forEach(e=>{
                            finalArr.push(e)
                        })
                        mapped3.forEach(e=>{
                            finalArr.push(e)
                        })
                        console.log(finalArr)
                        // let year = new Date().getFullYear() 
                        //  let targetYear = new Date().getFullYear() + 40
                        //  for(year; year < targetYear; year++){
                        //      console.log(year)
                        //      }
                    // console.log(filter)
                    return this.$lodash.sortBy(finalArr, 'Year')
            },

            viewBack(){
                this.isView = false,
                this.dialogItems = ''
                this.viewTitle = ''
            },

            findCoverage(item, coverage){
                let filtered = this[coverage].filter(e=>{
                    return e['.key'] == item
                })
                return filtered
            },

            viewItem(item, plan){
                this.isView = true
                let arrId = []
                item.items.forEach(e=>{
                    arrId.push(e['.key'])
                })
                console.log(arrId)
                 let filter2 = this[plan].filter(e=>{
                        return arrId.includes(e.CoverageId)
                    })
                    
                    // console.log(filter2)
                    let grouped = this.$lodash.groupBy(filter2,'RiskName.RiskName')
                    let mapped = this.$lodash.map(grouped, function(items, RiskName){
                        return {
                            RiskName: RiskName,
                            ORMP: items
                        }
                    })
                    // console.log(grouped)
                    // console.log(item)
                    // console.log(mapped)
                    let max = this.findMax(mapped) 
                    console.log(max)
                    this.dialogItems = max
                    this.viewTitle = item.title
                    // this.dialogReport = true
                    // this.dialogItems = {max: max, title: item.title}
            },

            returnValue(item){
                if(item.title == 'January to June'+ ' ' +item.Year){
                    let arrDates = [item.Year+'-01', item.Year+'-02', item.Year+'-03', item.Year+'-04', item.Year+'-05', item.Year+'-06']
                    let arrDatesItem = []
                    item.items.forEach(e=>{
                        arrDatesItem.push(e.startDate)
                    })
                    let uniqArrDatesItem = this.$lodash.uniq(arrDatesItem)
                    // console.log(arrDates)
                    // console.log(uniqArrDatesItem)
                    let filter = arrDates.filter(e=>{
                        return uniqArrDatesItem.includes(e)
                    })
                    return ((filter.length/arrDates.length)*100).toFixed(1)
                } else {
                    let arrDates = [item.Year+'-07', item.Year+'-08', item.Year+'-09', item.Year+'-10', item.Year+'-11', item.Year+'-12']
                    let arrDatesItem = []
                    item.items.forEach(e=>{
                        arrDatesItem.push(e.startDate)
                    })
                    let uniqArrDatesItem = this.$lodash.uniq(arrDatesItem)
                    // console.log(arrDates)
                    // console.log(uniqArrDatesItem)
                    let filter = arrDates.filter(e=>{
                        return uniqArrDatesItem.includes(e)
                    })
                    return ((filter.length/arrDates.length)*100).toFixed(1)
                }
            },
            // returnLastSixMonths(plan){
            //     // if(this.returnAllCoverage(plan).length == 0){
            //     //     return []
            //     // } else {
            //         // let arr = []
            //         // //let arr2 = []
            //         // let filter = this.returnAllCoverage(plan).filter(e=>{
            //         //     return e.startDate < new Date().getFullYear() + '-06'
            //         // })
            //         // filter.forEach(e=>{
            //         //     arr.push(e['.key'])
            //         // })
            //         // console.log(arr)
            //         let filter2 = this.ORMP.filter(e=>{
            //             return arr.includes(e.CoverageId)
            //         })
                    
            //         // console.log(filter2)
            //         let grouped = this.$lodash.groupBy(filter2,'RiskName.RiskName')
            //         let mapped = this.$lodash.map(grouped, function(items, RiskName){
            //             return {
            //                 RiskName: RiskName,
            //                 ORMP: items
            //             }
            //         })
            //         // console.log(grouped)
            //         return mapped
            //         // let max = this.$lodash.maxBy(mapped, 'ORMP.Vulnerability')
            //         // console.log(max)
            //     //    let res = mapped.forEach(e=>{
            //     //         this.findMax(e.ORMP)
            //     //     })
            //     //     console.log(res)
            
            //     // }
            // },

            findMax(item){
                let arr = []
                item.forEach(e=>{   
                    arr.push(this.$lodash.maxBy(e.ORMP, 'Vulnerability'))
                })
                return arr
            },

            returnCoverageTranspired(coverage){
                 if(this[coverage].length == 0){
                    return []
                } else if (coverage == 'PRMPCoverage'){
                       let filtered = this[coverage].filter(a=>{
                            return a.Product == this.selectedProduct && a.isTranspired == true
                        })       
                    return filtered
                } else {
                    let before = this[coverage]
                    let self = this

                    let user = self.$store.getters['useraccount/isAuthenticated']
                    
                    if(user.department !== 'RISK MANAGEMENT TEAM'){
                        return before.filter(a=>{
                            return a.Department == user.department && a.isTranspired == true
                        })          
                    }

                    if(self.deptFilter !== 'ALL DEPARTMENTS'){
                        return before.filter(a=>{
                            return a.Department == self.deptFilter && a.isTranspired == true
                        })
                    }

                    return before
                }
            },

            returnAllCoverage(){
                let before = this.ORMPCoverage
                let self = this

                let user = self.$store.getters['useraccount/isAuthenticated']
                
                if(user.department !== 'RISK MANAGEMENT TEAM'){
                    return before.filter(a=>{
                        return a.Department == user.department
                    })          
                }

                if(self.deptFilter !== 'ALL DEPARTMENTS'){
                    return before.filter(a=>{
                        return a.Department == self.deptFilter
                    })
                }

                return before
            },

            async approveCoverage(item){
                let self = this
                let report = {...item}
                let key = item['.key']
                let user = self.$store.getters['useraccount/isAuthenticated']
                report.isApproved = 'Approved'
                report.approvedBy = user
                if(item['isTranspired'] == false){
                    const confirm = await this.$dialog.confirm({
                        text: `Are you sure you want to approve this ORMP?`,
                        title: `ORMP Approval`
                    })
                    if(confirm){
                        self.$db.collection('ORMPCoverage').doc(key).set(report)
                        .then(async()=>{
                            await self.$store.dispatch('logs/createNotifs', {collection:'ORMPCoverage',documentKey:key,module: 'ORMP',action: 'Approved ORMP Coverage'}) 
                            self.$dialog.notify.success(`Approved ORMP Coverage`, {
                                position: 'bottom-right',
                                timeout: 3000
                            })                     
                        })
                    }
                } else {
                    const confirm = await this.$dialog.confirm({
                        text: `Are you sure you want to approve this transpired ORMP?`,
                        title: `Transpired ORMP Approval`
                    })
                    if(confirm){
                        self.$db.collection('ORMPCoverage').doc(key).set(report)
                        .then(async()=>{
                            await self.$store.dispatch('logs/createNotifs', {collection:'ORMPCoverage',documentKey:key,module: 'ORMP',action: 'Approved ORMP Coverage'}) 
                            self.$dialog.notify.success(`Approved Transpired ORMP Coverage`, {
                                position: 'bottom-right',
                                timeout: 3000
                            })                     
                        })
                    }
                }
            },

            async unapproveCoverage(item){
                let self = this
                let report = {...item}
                let key = item['.key']
                let user = self.$store.getters['useraccount/isAuthenticated']
                report.isApproved = 'Pending'
                report.approvedBy = user
                if(item['isTranspired'] == false){
                    const confirm = await this.$dialog.confirm({
                        text: `Are you sure you want to cancel approval of this ORMP?`,
                        title: `Cancel ORMP Approval`
                    })

                    if(confirm){
                        self.$db.collection('ORMPCoverage').doc(key).set(report)
                        .then(async()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection:'ORMPCoverage',documentKey:key,module: 'ORMP',action: 'Cancelled Approval of Transpired ORMP Coverage'}) 
                        self.$dialog.notify.success(`Cancelled Approval of ORMP Coverage`, {
                            position: 'bottom-right',
                            timeout: 3000
                            })                     
                        })
                    } 
                } else {
                    const confirm = await this.$dialog.confirm({
                        text: `Are you sure you want to cancel approval of this transpired ORMP?`,
                        title: `Cancel Transpired ORMP Approval`
                    })

                    if(confirm){
                        self.$db.collection('ORMPCoverage').doc(key).set(report)
                        .then(async()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection:'ORMPCoverage',documentKey:key,module: 'ORMP',action: 'Cancelled Approval of Transpired ORMP Coverage'}) 
                        self.$dialog.notify.success(`Cancelled Approval of Transpired ORMP Coverage`, {
                            position: 'bottom-right',
                            timeout: 3000
                            })                     
                        })
                    } 
                }
            },

        findORMPItems(item, collection){
        let arr = []
        let filter = this[collection].filter(e => {
         return e.CoverageId === item['.key'] 
        })
        filter.forEach(e=> {
          arr.push(e['.key'])
        })
        return arr
        },

        async deleteItem (item) {
            if(this.$route.path == '/viewallcoverage'){
            let self = this
            let deletedORMPItems = this.findORMPItems(item, 'ORMP')
            let deletedORMPLevels = this.findORMPItems(item, 'ORMPLevels')

            const confirm = await this.$dialog.confirm({
                text: "Once this ORMP Coverage has been deleted all ORMP Rows and Levels will also be deleted and cannot be restored. Are you sure you want to continue?",
                title: `WARNING: THIS ACTION CANNOT BE UNDONE`
            })

                if(confirm){
                    self.$db.collection('ORMPCoverage').doc(item['.key']).delete()
                    .then(()=>{
                    let deleteQuery = self.$db.collection('ORMP').where("CoverageId", "==", item['.key'])
                    deleteQuery.get().then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                        doc.ref.delete();
                        });
                    });
                    }).then(()=>{
                    let deleteQuery = self.$db.collection('ORMPLevels').where("CoverageId", "==", item['.key'])
                    deleteQuery.get().then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                        doc.ref.delete();
                        });
                    });
                    })
                    .then(async()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection: 'ORMPCoverage',documentKey:deletedORMPItems,module: 'ORMP',action: 'Deleted ORMP Coverage'}) 
                        await self.$store.dispatch('logs/createNotifs', {collection: 'ORMPLevels',documentKey:deletedORMPLevels,module: 'ORMP',action: 'Deleted ORMP Levels'}) 
                        self.$dialog.notify.success("Deleted Item from All ORMP's", {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })
                }

            } else {
                let self = this
                let deletedORMPItems = this.findORMPItems(item, 'PRMP')
                let deletedORMPLevels = this.findORMPItems(item, 'PRMPLevels')

                const confirm = await this.$dialog.confirm({
                    text: "Once this PRMP Coverage has been deleted all PRMP Rows and Levels will also be deleted and cannot be restored. Are you sure you want to continue?",
                    title: `WARNING: THIS ACTION CANNOT BE UNDONE`
                })

                if(confirm){
                    self.$db.collection('PRMPCoverage').doc(item['.key']).delete()
                    .then(()=>{
                    let deleteQuery = self.$db.collection('PRMP').where("CoverageId", "==", item['.key'])
                    deleteQuery.get().then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                        doc.ref.delete();
                        });
                    });
                    }).then(()=>{
                    let deleteQuery = self.$db.collection('PRMPLevels').where("CoverageId", "==", item['.key'])
                    deleteQuery.get().then(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {
                        doc.ref.delete();
                        });
                    });
                    })
                    .then(async()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection: 'PRMPCoverage',documentKey:deletedORMPItems,module: 'PRMP',action: 'Deleted PRMP Coverage'}) 
                        await self.$store.dispatch('logs/createNotifs', {collection: 'PRMPLevels',documentKey:deletedORMPLevels,module: 'PRMP',action: 'Deleted PRMP Levels'}) 
                        self.$dialog.notify.success("Deleted Item from All PRMP's", {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })
                }
            }
    
        },
    },
}
</script>
<style scoped>
a:link {
  text-decoration: none;
}
</style>