var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 py-8"},[_c('div',{staticClass:"text-h5 font-weight-bold grey--text text--darken-2 mb-8"},[(_vm.isView == true)?_c('span',[_c('v-icon',{on:{"click":_vm.viewBack}},[_vm._v("mdi mdi-arrow-left")]),_vm._v(" "),_c('span',[_vm._v("Highest Risks for "+_vm._s(_vm.viewTitle))])],1):_vm._e(),(_vm.isView == false)?_c('span',[_c('v-icon',[_vm._v("mdi-format-list-text")]),_vm._v(" View All ORMP")],1):_vm._e(),_c('div',{staticClass:"float-right row mr-2"},[(_vm.isView == false)?_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.returnORMPFilter),expression:"returnORMPFilter"}],staticClass:"mr-4 col",staticStyle:{"width":"200px"},attrs:{"item-text":"departmentName","item-value":"productName","items":_vm.returnDept,"label":"Select Department","hide-details":"","outlined":"","dense":""},model:{value:(_vm.deptFilter),callback:function ($$v) {_vm.deptFilter=$$v},expression:"deptFilter"}}):_vm._e(),_c('router-link',{attrs:{"to":{name: 'CreateCoverage'},"tag":"button"}},[_c('v-btn',{staticClass:"mb-2 col",attrs:{"color":"primary","dark":""}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New ORMP ")],1)],1)],1)]),(_vm.isView == false)?_c('div',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" ORMP's "),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",staticStyle:{"width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchORMP),callback:function ($$v) {_vm.searchORMP=$$v},expression:"searchORMP"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.searchORMP,"headers":_vm.headers,"items":_vm.returnCoverage(),"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ml-1"},[_c('router-link',{attrs:{"to":{name: 'ViewAllORMP', params: {id: item['.key']}}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-arrow-right-bold-circle ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),(_vm.returnORMPFilter && item.isApproved == 'Pending')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.approveCoverage(item)}}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e(),(_vm.returnORMPFilter && item.isApproved == 'Approved')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.unapproveCoverage(item)}}},[_vm._v(" mdi-close-circle ")]):_vm._e()],1)]}},{key:"item.CreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A'))+" ")]}},{key:"item.DateCovered",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startDate)+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true}],null,false,1313869263)})],1)],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Transpired ORMP's "),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",staticStyle:{"width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchTranspiredORMP),callback:function ($$v) {_vm.searchTranspiredORMP=$$v},expression:"searchTranspiredORMP"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"search":_vm.searchTranspiredORMP,"items":_vm.returnCoverageTranspired('ORMPCoverage'),"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"ml-1"},[_c('router-link',{attrs:{"to":{name: 'ViewAllORMP', params: {id: item['.key']}}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-arrow-right-bold-circle ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),(_vm.returnORMPFilter && item.isApproved == 'Pending')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.approveCoverage(item)}}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e(),(_vm.returnORMPFilter && item.isApproved == 'Approved')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.unapproveCoverage(item)}}},[_vm._v(" mdi-close-circle ")]):_vm._e()],1)]}},{key:"item.CreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A'))+" ")]}},{key:"item.DateCovered",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startDate)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" There are no transpired ORMP's yet ")]},proxy:true}],null,false,788886598)})],1)],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Last Six Months "),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",staticStyle:{"width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchLastSixMonthsORMP),callback:function ($$v) {_vm.searchLastSixMonthsORMP=$$v},expression:"searchLastSixMonthsORMP"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.searchLastSixMonthsORMP,"headers":_vm.headers2,"items":_vm.returnCoverageSixMonths(),"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.ViewDetails",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ml-4",attrs:{"small":""},on:{"click":function($event){return _vm.viewItem(item, 'ORMP')}}},[_vm._v(" mdi mdi-arrow-right-bold-circle ")])]}},{key:"item.Progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":_vm.returnValue(item),"color":"primary","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('strong',[_vm._v(_vm._s(_vm.returnValue(item))+"%")])]},proxy:true}],null,true)})]}},{key:"no-data",fn:function(){return [_vm._v(" There are no ORMP's yet ")]},proxy:true}],null,false,4127842432)})],1)],1)],1)],1):_vm._e(),(_vm.isView == true)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers3,"items":_vm.dialogItems,"item-key":".key"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('router-link',{attrs:{"to":{name: 'RiskLevel', params: {id: _vm.getID(item)}}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-flag-variant ")])],1)],1)]}},{key:"item.DateCovered",fn:function(ref){
var item = ref.item;
return [(_vm.$route.path == '/viewallprmpcoverage')?_c('td',[_vm._v(" "+_vm._s(_vm.findCoverage(item.CoverageId, 'PRMPCoverage')[0].startDate)+" ")]):_c('td',[_vm._v(" "+_vm._s(_vm.findCoverage(item.CoverageId, 'ORMPCoverage')[0].startDate)+" ")])]}},{key:"item.AffectedStakeholders",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.AffectedStakeholders),function(stakeholder,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(stakeholder.AffectedStakeholder)),(index != (item.AffectedStakeholders.length -1))?_c('span',[_vm._v(",")]):_vm._e()])])}),0)]}},{key:"item.RiskTreatmentOwner",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.RiskTreatmentOwner),function(riskowner,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(riskowner.RiskTreatmentOwner)),(index != (item.RiskTreatmentOwner.length -1))?_c('span',[_vm._v(",")]):_vm._e()])])}),0)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,false,2458685373)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }